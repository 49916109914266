@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;700&display=swap');

:root {
  --color-success: #10b981;
  --color-info: #4c8df6;
  --color-error: #ef4444;
  --color-action: #6d28d9;
  --color-egg: #ffcf00;
  --color-primary: #36474f;
  --color-secondary: #5b5b5b;
  --shadow: 0 10px 10px 0 #d3d3d369;
  --border-radius: 5px;
}

html,
body {
  min-height: 100vh;
  margin: 0;
  color: var(--color-secondary);
}

body {
  font-family: 'Roboto', sans-serif;
}

/* Main container */
#root > .main {
  margin-left: 300px;
  padding-right: 2em;
  padding-bottom: 2em;
  max-width: 1400px;
  min-height: 100vh;
}

@media only screen and (max-width: 1300px) {
  #root > .main {
    margin-left: 16.5em;
  }
}

#root > .main.login {
  margin-left: 0;
  display: grid;
  min-width: 100%;
}

/* Helpers */
.flat {
  border-radius: 0;
}

.w-100 {
  width: 100%;
}

.flex {
  display: flex;
  justify-content: space-around;
}

.flex-search {
  display: flex;
  justify-content: space-between;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.flex-left {
  display: flex;
  justify-content: left;
  gap: 0.5em;
}

.flex-right {
  display: flex;
  justify-content: right;
  gap: 0.5em;
}

.flex-elements {
  display: flex;
  gap: 1.5em;
}

.flex-start {
  margin: unset !important;
}

.flex-column {
  display: flex;
  flex-direction: column;
  gap: 0.75em;
}

.date-block {
  padding-right: 2em;
}

.column-width {
  width: 40%;
  margin-right: 0% !important;
}

.column-span {
  grid-column: 1 / -1;
}

.column-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 2em;
  margin: 0 auto;
  justify-content: center;
}

.column-2 > button {
  grid-column: 1 / span 1;
}

.column-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1em;
  margin: 0 auto;
  justify-content: center;
}

.center-content {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.capitalize {
  text-transform: capitalize;
}

.align-self-center {
  align-self: center;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

/* Components */

* {
  border-radius: var(--border-radius);
}

p.danger,
i.danger,
span.danger,
div.danger {
  color: var(--color-error);
}

p.success,
i.success,
span.success,
div.success {
  color: var(--color-success);
}

p.info,
i.info,
span.info,
div.info {
  color: var(--color-info);
}

p.action,
i.action,
span.action,
div.action {
  color: var(--color-action);
}

button.outlined {
  all: unset;
  cursor: pointer;
}

a,
i.outlined,
button.outlined {
  text-decoration: none;
  font-weight: 400;
  color: var(--color-action);
  cursor: pointer;
}

a:hover,
i.outlined:hover button.outlined:hover {
  text-decoration: underline;
}

a.active {
  color: var(--color-primary);
}

.tags {
  color: white;
  display: inline-block;
  text-align: center;
  padding: 2px 19px;
  border-radius: 20px;
  background-color: var(--color-primary);
  min-width: 15px;
}

.tags.success {
  color: white;
  background-color: var(--color-success);
}

.tags.info {
  color: white;
  background-color: var(--color-info);
}

.tags.error {
  color: white;
  background-color: var(--color-error);
}

.tags.action {
  color: white;
  background-color: var(--color-action);
}

.tags.egg {
  color: black;
  background-color: var(--color-egg);
}

.tags.primary {
  color: white;
  background-color: var(--color-primary);
}

.tags.secondary {
  color: white;
  background-color: var(--color-secondary);
}

/* Form styles */

form > * {
  margin: 0.5em 0;
}

*:invalid {
  border-color: var(--color-error);
}

input {
  flex-grow: 2;
}

input,
textarea,
select,
button {
  padding: 0.7em;
  border: 1px solid var(--color-secondary);
  outline: 0;
}

label {
  display: flex;
  flex-direction: column;
  opacity: 0.9;
  margin: 0.5em 0;
  gap: 5px;
}

input[type='checkbox'] {
  transform: scale(1.2);
  cursor: pointer;
}

input[type='radio'] {
  transform: scale(1.2);
  cursor: pointer;
}

::placeholder {
  opacity: 0.8;
}

.column-2-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 5em;
  margin: 0 auto;
  justify-content: center;
}

.invalid-none:invalid {
  border-color: initial !important;
}

.fieldset {
  margin-bottom: 1.5em;
}

.bold-legend {
  font-weight: 700;
}

.select-z-index {
  z-index: unset;
}

.label-height {
  height: 144px;
  max-height: 144px;
}

.label-height select {
  height: 109px;
}

.checkBox {
  display: flex;
  grid-column: 1 / -1;
  gap: 1.5em;
  flex-wrap: wrap;
  margin: 0.5em 0;
  font-family: initial;
}

/* Button styles */

button {
  cursor: pointer;
  background-color: white;
}

button + button,
input + button {
  margin-left: 1em;
}

button:hover {
  filter: brightness(0.95);
}

button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

button:disabled:hover {
  filter: none;
}

button.solid {
  color: white;
  border-color: var(--color-primary);
  background-color: var(--color-primary);
}

button.action {
  color: white;
  border-color: var(--color-action);
  background-color: var(--color-action);
}

button.danger {
  color: white;
  border-color: var(--color-error);
  background-color: var(--color-error);
}

button.egg {
  color: black;
  border-color: var(--color-egg);
  background-color: var(--color-egg);
}

button.success {
  color: white;
  border-color: var(--color-success);
  background-color: var(--color-success);
}

button.info {
  color: white;
  border-color: var(--color-info);
  background-color: var(--color-info);
}

button.big {
  flex-grow: 2;
}

.display-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

/* Icons */

/* Info Icon     */
.tooltip:hover {
  animation-play-state: paused;
}

.tooltip:hover .info {
  visibility: visible;
}

/* Tables */

table {
  font-size: smaller;
  border: 1px solid #cecece;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: var(--shadow);
  min-width: 100%;
  margin-bottom: 2em;
}

thead,
tfoot {
  background-color: #f3f3f3;
}

thead th {
  text-align: left;
  font-weight: 600;
  color: var(--color-secondary);
}

tr {
  color: black;
}

.row-disposition {
  display: flex;
  flex-wrap: wrap;
}

tr:hover {
  background-color: #f3f3f3;
}

td,
th {
  padding: 10px;
  border-bottom: 1px solid #ececec;
  border-radius: 0;
  font-weight: 400;
}

th:not(:first-child),
td:not(:first-child) {
  text-align: center;
}

tr:last-child td {
  border-bottom: none;
}

.icono {
  position: absolute;
  top: 10px;
  left: 445px;
}

.svg {
  height: 40px;
  width: 40px;
  justify-content: flex-end;
}

.list-style-none {
  list-style: none;
}

.dates-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em;
  border-bottom: 1px solid #ececec;
}

ul.timeline {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.timeline li {
  border-radius: 0;
  position: relative;
}

ul.timeline li::before {
  position: absolute;
  content: '';
  width: 2px;
  height: 100%;
  background-color: lightgray;
}

ul.timeline li:first-child::before {
  height: 50%;
  transform: translateY(100%);
}

ul.timeline li:last-child::before {
  height: 50%;
  transform: translateY(-25%);
}

ul.timeline li::after {
  box-sizing: border-box;
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  background-color: grey;
  left: -4px;
  top: 50%;
  transform: translateY(-50%);
}

ul.timeline li.info::after {
  background-color: var(--color-info);
}

ul.timeline li.error::after {
  background-color: var(--color-error);
}

ul.timeline li.action::after {
  background-color: var(--color-action);
}

ul.timeline li.success::after {
  background-color: var(--color-success);
}

ul.timeline li.egg::after {
  background-color: var(--color-egg);
}

progress[value] {
  appearance: none;
  border: none;
  width: 100%;
  height: 15px;
  background-color: whiteSmoke;
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5) inset;
  position: relative;
}

progress[value]::-webkit-progress-bar,
progress:not(value)::-webkit-progress-bar {
  background-color: whiteSmoke;
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5) inset;
}

progress[value]::-webkit-progress-value {
  position: relative;
  background-size:
    35px 20px,
    100% 100%,
    100% 100%;
  border-radius: 3px;
}

progress::-webkit-progress-value {
  background-image:
    -webkit-linear-gradient(
      135deg,
      transparent,
      transparent 33%,
      rgba(0, 0, 0, 0) 33%,
      rgba(0, 0, 0, 0) 66%,
      transparent 66%
    ),
    -webkit-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.2)),
    -webkit-linear-gradient(left, #09c, #09c);
}

summary {
  cursor: pointer;
}
