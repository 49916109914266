table.filter thead,
table.filter thead tr:hover {
  background-color: #fff;
}

table.filter thead {
  padding-bottom: 0.75em;
}

table.filter thead th {
  border: 0;
}

table.filter thead tr th input {
  border-color: lightgrey;
  font-weight: initial;
  display: block;
}

table.filter thead tr th {
  font-weight: initial;
}

.switch {
  transform: scale(0.5);
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #6d28d9;
}

input:focus + .slider {
  box-shadow: 0 0 1px #6d28d9;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

button.downloadCSV {
  font-size: 14px;
  float: right;
  margin: 0.5em 2em;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.5em;
  grid-gap: 0.5em;
  gap: 0.8em;
  margin-bottom: 2em;
  font-size: 14px;
}

.pagination button.outlined {
  padding: 6px;
  text-decoration: none;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
