.content {
  height: 300px;
  display: grid;
}

.sectionNav {
  display: flex;
  gap: 10px;
}

.sectionNav i.active {
  color: var(--color-action);
}

.content > section {
  width: 100%;
}

.unroll .buttons {
  text-align: right;
}

.LoginSection {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}
