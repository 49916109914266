.image {
  height: 70px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
}
.title {
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid #b9b9b9;
  border-radius: 0;
  padding-bottom: 1em;
  margin: 2em 0 1em 0;
  gap: 1em;
}

.label h1,
.label p {
  margin: 0;
}
.label h4 {
  font-weight: normal;
}
.children {
  display: flex;
  gap: 1em;
  flex-grow: 2;
  justify-content: flex-end;
  align-items: center;
}

.subTitle {
  cursor: pointer;
  display: flex;
  width: 75vh;
  overflow-wrap: anywhere;
}
