div.students {
  display: flex;
  margin: 25px 0;
  gap: 5px;
}

nav {
  display: flex;
  justify-content: space-evenly;
}

nav button.active,
nav button.inactive:hover {
  all: unset;
  padding-bottom: 3px;
  color: var(--color-action);
  text-decoration: none;
  border-bottom: 2px solid;
}

nav button.inactive {
  all: unset;
  padding-bottom: 3px;
  color: var(--color-info);
  text-decoration: none;
  border-bottom: 2px solid transparent;
}
