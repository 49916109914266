.section {
  max-width: 300px;
}

.trackings-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 2em;
}

.tracking-item {
  display: flex;
  flex-direction: column;
  float: left;
}

.div-new {
  position: absolute;
  right: 20px;
  top: 150px;
}

.listContainer {
  overflow: auto;
  padding-left: 1em;
}

.customTimeline {
  line-height: 1.7;
}

.ago {
  font-size: x-small;
  margin-left: 1ch;
}
